import http from '../clients/Axios'
export default class LaporanHutangUsahaService {
  async exportLaporanDetail(supplier_id, end_date) {
    const params = new URLSearchParams()

    if (supplier_id) {
      params.append('filter[supplier_id]', supplier_id)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/hutang/detail/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanRangkuman(supplier_id, end_date) {
    const params = new URLSearchParams()

    if (supplier_id) {
      params.append('filter[supplier_id]', supplier_id)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/hutang/rangkuman/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanKartu(end_date) {
    const params = new URLSearchParams()

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/hutang/kartu/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  /* async exportLaporanKartu(supplier_id, start_date, end_date) {
    const params = new URLSearchParams()

    if (supplier_id) {
      params.append('filter[supplier_id]', supplier_id)
    }

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/hutang/kartu/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  } */

  async exportLaporanMutasi(supplier_id, start_date, end_date) {
    const params = new URLSearchParams()

    if (supplier_id) {
      params.append('filter[supplier_id]', supplier_id)
    }

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/hutang/mutasi/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportLaporanPembayaran(supplier_id, start_date, end_date) {
    const params = new URLSearchParams()

    if (supplier_id) {
      params.append('filter[supplier_id]', supplier_id)
    }

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/hutang/pembayaran/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
}
